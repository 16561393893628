/* Artikel im Spaltensatz */
.mod_article--column {
  /* Large */
  @media only screen and (min-width : 64em) {
	column-count : 2;
	column-gap   : 2.5em ;
	column-rule  : 1px dotted currentColor;
  }
  /* xLarge */
  @media only screen and (min-width : 90em) {
	column-count : 3;
  }
  & > * {
	break-inside : avoid;
  }
}
#tagline {
  /* Large */
  @media only screen and (min-width : 64em) {
	color            : var(--clr-tagline);
	background-color : var(--bg-tagline);
	background-image : linear-gradient(180deg, var(--secondary), var(--secondary) 6%, var(--primary) 6%, var(--primary) 83%, #ffffff 83%, #ffffff 100%);
  }
  .inside {
	display               : grid;
	grid-template-columns : 1fr;
	grid-template-rows    : auto 1fr;
	row-gap               : var(--gap);
	max-width             : var(--content-max-width);
	margin-inline         : auto;
	/* Small */
	@media only screen and (min-width : 37.5em) {
	  row-gap            : 0;
	}
	/* Large */
	@media only screen and (min-width: 64em) {
	  height: 100%;
	  grid-template-rows    : 83% 1fr;
	}

  }
}

.tagline--grafik {
  color            : var(--clr-tagline);
  background-color : var(--bg-tagline);
  padding-block-start: var(--p);
  padding-inline : var(--p);
  /* Small */
  @media only screen and (min-width : 37.5em) {
	padding-block-start : 0;
	background-image    : linear-gradient(180deg, var(--secondary), var(--secondary) 6%, var(--primary) 6%, var(--primary) 80%, #ffffff 80%, #ffffff 100%);
	grid-column         : 1 / -1;
	grid-row            : 1 /span 2;
  }
  /* Large */
  @media only screen and (min-width : 64em) {
	background-color : transparent;
	background-image : none;
  }
}

.tagline--text {
  align-self : end;
  padding-inline : var(--p);
  /*z-index    : 100;*/
  /* Large */
  @media only screen and (min-width : 64em) {
	grid-column : 1 / -1;
	grid-row    : 1 / span 1;
	.content-headline {
	  margin-block-end : .5rem;
	}
  }
}

footer,
#footer {
  color            : var(--clr-footer);
  background-color : var(--bg-footer);
  font-size        : var(--fs-small);
  .inside {
	max-width     : var(--content-max-width);
	padding       : var(--p);
	margin-inline : auto;
	/* Large */
	@media only screen and (min-width : 64em) {
	  display         : flex;
	  justify-content : space-between;
	}
  }
}
/* Formular */
.ce_form {
  max-width        : 65ch;
  margin-block-end : var(--m);
}

fieldset {
  border  : none;
  padding : 0;
}

input,
textarea {
  background-color : var(--gray-300);
  width            : 100%;
  border-radius    : 0;
  border           : none;
  padding          : 6px 12px;
  margin-block-end : var(--dist-300);
}

.submit {
  color            : #ffffff;
  background-color : var(--primary);
  border-radius    : 0;
  border           : none;
  padding          : .5rem 1rem;
  font-size        : var(--fs-400);
  text-transform   : uppercase;
  cursor           : pointer;
}


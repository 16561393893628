/* Body */
body {
  background-color : var(--bg);
  color            : var(--clr);
  /* noinspection CssNoGenericFontName */
  font-family      : var(--ff);
  font-size        : var(--fs);
  line-height      : var(--lh);
  overflow-y       : scroll;
  min-height       : 100%;
  margin           : 0;
  hyphens          : auto;
  @media print {
	background : transparent none;
	font-size  : 12pt;
  }
}

/* alle Überschriften und Inhalt der optisch wie Überschrift formatiert ist */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight      : var(--fw-headline);
  line-height      : var(--lh-headline);
  hyphens          : none;
  text-wrap        : balance;
  margin-block-end : var(--m-headline);
  & span {
	font-size  : .75em;
	font-style : italic;
  }
}

h1 {
  --_fs-h1  : var(--fs-h1, 2rem);
  font-size : var(--_fs-h1);
  /* Large */
  @media only screen and (min-width : 64em) {
	--_fs-h1 : clamp(1.3125rem, 2.6042vw + -.3542rem, 2.25rem);
  }
}

h2 {
  font-size : var(--fs-h2);
}

/* Text */
p {
  text-wrap        : pretty;
  margin-block-end : var(--m);
}

/* Link */
a {
  --_clr-link     : var(--clr-link);
  color           : var(--_clr-link);
  text-decoration : none;
  &:is(:hover,:focus-visible) {
	--_clr-link     : var(--clr-link-hover);
	text-decoration : underline;
  }
}

/* Strong */
strong {
  font-weight : var(--fw-bold);
}

/* Listen */
ul {
  & li {
	list-style-type : disc;
	& li {
	  list-style-type : square;
	  & li {
		list-style-type : circle
	  }
	}
  }
}

ol {
  & li {
	list-style-type : decimal;
	& li {
	  list-style-type : upper-alpha;
	  & li {
		list-style-type : lower-alpha;
	  }
	}
  }
}

/* Address */
address {
  font-style       : normal;
  margin-block-end : var(--m);
}

/* Bilder */
figcaption {
  font-size     : var(--fs-small);
  padding-block : var(--dist-100);
  &:empty {
	display : none;
  }
}

/* Navigation allgemein */
nav,
.nav {
  position : relative;
  & ul {
	margin     : 0;
	padding    : 0;
	list-style : ''; /* besser für Screenreader */
	& li {
	  list-style : ''; /* besser für Screenreader */
	  & li {
		list-style : ''; /* besser für Screenreader */
	  }
	  & li {
		list-style : ''; /* besser für Screenreader */
	  }
	}
  }
  & a,
  & a:is(:hover, :focus) {
	text-decoration : none;
	border          : none;
	white-space     : nowrap;
  }
}








/* Copyright */
.content-text--copyright {
  color      : oklch(from currentColor calc(l - .12) c h);
  text-align : center;
  margin     : 0;
  /* xLarge */
  @media only screen and (min-width : 90em) {
	text-align : left;
  }
  & a {
	--_clr-link     : currentColor;
	color           : var(--_clr-link);
	text-decoration : none;
	&:is(:hover, :focus) {
	  text-decoration : underline;
	}
  }
}

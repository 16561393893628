/* Hauptnavigation (eine Ebene) */
.nav--main {
  display        : none;
  font-size      : var(--fs-nav-main);
  font-weight    : var(--fw-nav-main);
  text-transform : uppercase;
  /* Medium */
  @media only screen and (min-width : 48em) {
	display          : block;
	align-self       : flex-end;
	margin-block-end : 3%;
  }
  /* xLarge */
  @media only screen and (min-width : 90em) {
	--col-start : 8;
	--col-width : 5;
  }
  & a,
  & strong {
	white-space : nowrap;
  }
  & a {
	--_clr-link : currentColor;
	&:is(:hover, :focus-within) {
	  --_clr-link : var(--clr-link);
	}
  }
  & strong {
	font-weight : 300;
	color       : var(--clr-link);
	&:is(:hover, :focus-within) {
	  --_clr-link : var(--clr-link);
	}
  }
  .level_1 {
	display : flex;
	flex-flow: column;
	gap     : var(--gap);
	/* Medium */
	@media only screen and (min-width: 48em) {
	  flex-flow: row;
	}
  }
}

/* mobile Toggler */
/* Checkbox ist über Klasse invisible ausgeblendet */
#toggler_mobile {
  /* Label stylen */
  & + label {
	background-color   : var(--bg-toggler);
	border-radius      : var(--br-toggler, 5px);
	cursor             : pointer;
	display            : block;
	grid-area          : nav-main;
	height             : var(--h-toggler, 50px);
	position           : relative;
	margin-block-start : 5px;
	width              : var(--w-toggler, 50px);
	z-index            : 100;
	/* Medium */
	@media only screen and (min-width : 48em) {
	  display : none;
	}
	.mobile_line {
	  background-color : var(--clr-toggler);
	  border-radius    : 2px;
	  display          : block;
	  height           : var(--h-toggler-line, 2px);
	  left             : 5px;
	  position         : absolute;
	  transform-origin : center;
	  transition       : 0.5s;
	  width            : calc(var(--w-toggler) - 10px);
	  &:nth-child(1) {
		top : calc((var(--h-toggler) / 4) - (var(--h-toggler-line) / 2));
	  }
	  &:nth-child(2) {
		top : calc(((2 * var(--h-toggler)) / 4) - (var(--h-toggler-line) / 2));
	  }
	  &:nth-child(3) {
		top : calc((3 * var(--h-toggler) / 4) - (var(--h-toggler-line) / 2));
	  }
	}
  }
  &:checked {
	/* Label stylen bei aktivierter Checkbox */
	& + label {
	  .mobile_line {
		&:nth-child(1) {
		  top       : calc((var(--h-toggler) / 4) - (var(--h-toggler-line) / 2) + 2px);
		  transform : translateY(calc(var(--h-toggler) / 4 - (var(--h-toggler-line) / 2))) rotate(-45deg);
		}
		&:nth-child(2) {
		  opacity : 0;
		}
		&:nth-child(3) {
		  top       : calc((3 * var(--h-toggler) / 4) - (var(--h-toggler-line) / 2) + 2px);
		  transform : translateY(calc((-1 * var(--h-toggler) / 4) - (var(--h-toggler-line) / 2))) rotate(45deg);
		}
	  }
	}
	/* Hauptnavigation oeffnen bei aktivierter Checkbox */
	& ~ .nav--main {
	  background-color  : var(--secondary);
	  display        : block;
	  height         : 100vh;
	  padding        : var(--p);
	  position       : absolute;
	  overflow-y     : auto;
	  z-index        : 50;
	  top            : 0;
	  left           : 0;
	  width          : 30ch;
	  border        : var(--border);
	}
  }
  &:focus-visible {
	/* Label stylen, wenn Focus ueber Tab erhalten */
	& + label {
	  outline        : 1px dotted;
	  outline-offset : 5px;
	}
  }
}

.nav--meta {
  text-transform : uppercase;
  margin-block   : var(--m);
  /* Large */
  @media only screen and (min-width : 64em) {
	margin-block : 0;
  }
  & ul {
	display         : flex;
	gap             : var(--gap);
	justify-content : center;
	/* Large */
	@media only screen and (min-width : 64em) {
	  justify-content : flex-start;
	}
  }
  & a {
	--_clr-link : currentColor;
	&:is(:hover, :focus-within) {
	  --_clr-link : var(--clr-link);
	}
  }
  & strong {
	font-weight : var(--fw);
	color       : var(--clr-link);
	&:is(:hover, :focus-within) {
	  --_clr-link : var(--clr-link);
	}
  }
}
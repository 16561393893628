/* Top-Link */
.toplink {
   text-align: right;
   margin-block-start: var(--dist-800);

  /* a { */
	/* display         : block; */
	/* text-decoration : none; */
	/* &::before { */
	/*   content       : ""; */
	/*   !* noinspection CssNoGenericFontName *! */
	/*   width         : 0; */
	/*   height        : 0; */
	/*   border-style  : solid; */
	/*   border-width  : 0 15px 20px 15px; */
	/*   transform     : rotate(360deg); */
	/*   border-color  : transparent transparent var(--clr-toplink) transparent; */
	/*   padding       : var(--p-toplink); */
	/*   margin-inline : auto; */
	/*   border-radius : var(--border-radius-toplink); */
	/* } */
	/* &:is(:hover,:focus) { */
	/*   &::before { */
	/* 	background-color : oklch(from var(--clr-toplink) calc(l - 0.1) c h); */
	/*   } */
	/* } */
  /* } */
}

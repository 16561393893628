.content-gallery {
  & ul {
	display               : grid;
	gap                   : var(--dist-200);
	grid-template-columns : repeat( auto-fit, minmax(270px, 1fr));
	padding               : 0;
	& li {
	  list-style : none;
	}
  }
  & figure {
	position      : relative;
	border        : var(--border);
	border-radius : var(--border-radius);
	box-shadow    : var(--box-shadow);
	padding       : .5rem
  }
}
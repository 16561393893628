#header {
  color            : var(--clr-header);
  background-color : var(--bg-header);
  .inside {
	display           : flex;
	gap               : var(--gap);
	flex-flow: wrap;
	justify-content   : space-between;
	max-width         : var(--content-max-width);
	margin-inline      : auto;
	padding : 0  var(--p) var(--p) var(--p);
  }
}
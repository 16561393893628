/* FARBSCHEMA */
:root {
  /* Farbschattierungen */
  --primary            : #99161b;
  --primary-lighness   : 44.03%;
  --primary-chroma     : 0.1645;
  --primary-hue        : 25.95;
  --secondary          : #908686;
  --secondary-lighness : 62.89%;
  --secondary-chroma   : 0.0121;
  --secondary-hue      : 17.48;
  --tertiary           : #0e5680;
  --tertiary-lighness  : 43.37%;
  --tertiary-chroma    : 0.0953;
  --tertiary-hue       : 241.32;
  /* Grauschattierungen ausgehen von brand */
  --gray-hue           : var(--primary-hue);
  --gray-chroma        : .01;
  --gray-100           : oklch(99% var(--gray-chroma) var(--gray-hue, none));
  --gray-200           : oklch(95% var(--gray-chroma) var(--gray-hue, none));
  --gray-300           : oklch(88% var(--gray-chroma) var(--gray-hue, none));
  --gray-400           : oklch(68% var(--gray-chroma) var(--gray-hue, none));
  --gray-500           : oklch(58% var(--gray-chroma) var(--gray-hue, none));
  --gray-600           : oklch(43% var(--gray-chroma) var(--gray-hue, none));
  --gray-700           : oklch(37% var(--gray-chroma) var(--gray-hue, none));
  --gray-800           : oklch(25% var(--gray-chroma) var(--gray-hue, none));
  --gray-900           : oklch(10% var(--gray-chroma) var(--gray-hue, none));
  /* Layoutfarben */
  --clr                : oklch(from #000 calc(l + .3) c h);
  --bg                 : ffffff;
}

/* ABSTÄNDE UND BREITEN */
:root {
  /* Veränderungen im Bereich zwischen Viewport 480px und Viewport 1400px */
  --dist-100          : clamp(.25rem, .8696vw + -.0109rem, .75rem); /* 4px - 12px */
  --dist-200          : clamp(.5rem, .8696vw + .2391rem, 1rem); /* 8px - 16px */
  --dist-300          : clamp(.75rem, .8696vw + .4891rem, 1.25rem); /* 12px - 20px */
  --dist-400          : clamp(1rem, .8696vw + .7391rem, 1.5rem); /* 16px - 24px */
  --dist-500          : clamp(1.25rem, .8696vw + .9891rem, 1.75rem); /* 12px -28px */
  --dist-600          : clamp(1.5rem, .8696vw + 1.2391rem, 2rem); /* 24px - 32px */
  --dist-700          : clamp(1.75rem, 1.3043vw + 1.3587rem, 2.5rem); /* 28px - 40px */
  --dist-800          : clamp(2rem, 1.7391vw + 1.4783rem, 3rem); /* 32px - 48px */
  --dist-900          : clamp(2.5rem, 1.7391vw + 1.9783rem, 3.5rem); /* 28px - 56px */
  --gap               : var(--dist-500);
  --p                 : var(--dist-400);
  --m                 : var(--dist-400);
  --content-max-width : calc(1600px + 2 * var(--p));
  --content-min-width : 320px;
}

/* TYPOGRAPHIE */
:root {
  --fs-100   : clamp(0.79rem, -0.016vi + 0.794rem, 0.781rem);
  --fs-200   : clamp(0.889rem, 0.088vi + 0.867rem, 0.938rem);
  --fs-300   : clamp(1rem, 0.227vi + 0.943rem, 1.125rem);
  --fs-400   : clamp(1.125rem, 0.409vi + 1.023rem, 1.35rem);
  --fs-500   : clamp(1.266rem, 0.644vi + 1.105rem, 1.62rem);
  --fs-600   : clamp(1.424rem, 0.946vi + 1.187rem, 1.944rem);
  --fs-700   : clamp(1.602rem, 1.329vi + 1.27rem, 2.333rem);
  --fs-800   : clamp(1.802rem, 1.813vi + 1.349rem, 2.799rem);
  --fs-900   : clamp(2.027rem, 2.422vi + 1.422rem, 3.359rem);
  --fs       : var(--fs-300);
  --fs-small : var(--fs-200);
  /* Font-Family */
  --ff       : 'Roboto', 'Verdana', 'Geneva', sans-serif;
  /* Font-Weight */
  --fw       : 400;
  --fw-bold  : 600;
  /* Line-Height */
  --lh       : 1.5;
}

/* BASE */
:root {
  --m-content      : var(--dist-900);
  /* Headline */
  --m-headline     : .5em;
  --fw-headline    : 300;
  --fs-h1          : var(--fs-700);
  --fs-h2          : var(--fs-600);
  --lh-headline    : 1.1;
  /* Link */
  --clr-link       : var(--primary);
  --clr-link-hover : oklch(from var(--clr-link) calc(l - .15) c h);
  /* Figure */
  --w-figure       : min(40vw, 500px);
/* Border */
  --border        : 1px solid var(--gray-400);
  --border-radius : 2px;
/* Schatten */
  --box-shadow    : 4px 4px 5px var(--gray-400);
}

/* COMPONENTS */
:root {
  /* Hauptnavigation */
  --fs-nav-main    : clamp(1.25rem, .9615vw + .7885rem, 1.75rem);
  --fw-nav-main    : 300;
  /* Toogler Hauptnavigation */
  --bg-toggler     : transparent;
  --clr-toggler    : var(--primary);
  --br-toggler     : 3px;
  --w-toggler      : 50px;
  --h-toggler      : 50px;
  --h-toggler-line : 4px;
}

/* LAYOUT */
:root {
  /* Header */
  --bg-header   : var(--secondary);
  --clr-header  : oklch(from var(--bg-header) calc(l + .6) .01 h);
  /* Tagline */
  --bg-tagline  : var(--primary);
  --clr-tagline : oklch(from var(--bg-tagline) calc(l + .6) .01 h);
  /* Footer */
  --bg-footer   : var(--secondary);
  --clr-footer  : oklch(from var(--bg-footer) calc(l + .6) .01 h);
}




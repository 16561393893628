/* Inhaltselement Text */
.content-text {
  margin-block-end : var(--m);
  & :last-child {
	margin-block-end : 0;
  }
}

.media--above {
  & figure {
	margin-block-end : var(--dist-900);
  }
}

.media--below {
  & figure {
	margin-block-start : var(--dist-500);
  }
}

.media--right {
  display : grid;
  gap     : var(--gap);
  /* Medium */
  @media only screen and (min-width : 48em) {
	grid-template-columns : 1fr 1fr;
  }
  & figure {
	/* Large */
	@media only screen and (min-width : 64em) {
	  justify-self : center;
	}
  }
  & img {
	max-width : 280px;
	/* Medium */
	@media only screen and (min-width : 48em) {
	  max-width : 350px;
	}
	/* xLarge */
	@media only screen and (min-width : 90em) {
	  max-width : 500px;
	}
  }
  .rte {
	order : -1;
  }
}











